//
import React, { Component, useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import axios from 'axios';

import {
  RowOffsetBottom,
  FieldLabel,
  SectionLabel,
  InputMain,
  Column,
  HalfContentWrapper,
  HalfContent,
  ColumnOffsetBottom,
} from '../../components/MainComponents/MainComponents';
import MapContainer from '../MapContainer/MapContainer';
import PlaceAutoCompleteComponent from '../../components/PlaceAutoCompleteComponent/PlaceAutoCompleteComponent';
import DropDownComponent from '../../components/DropDownComponent/DropDownComponent';
import {
  selectProfileObject,
  selectProfileErrors,
} from '../../redux/profile/profile.selectors';
import { getNames, getName } from '../../utils/country';
import OpeningTimesComponent from '../../components/OpeningTimesComponent/OpeningTimesComponent';
import { PlaceIdModal } from '../../components/GooglePlaceIDModal/PlaceIDModal';
import { newTheme } from '../../mainStyles';

const sortCityByLabel = (a, b) => {
  const labelA = a.label.toLowerCase();
  const labelB = b.label.toLowerCase();
  if (labelA < labelB) {
    return -1;
  }
  if (labelA > labelB) {
    return 1;
  }
  return 0;
};

const MapAddress = (props) => {
  const [city, setCity] = useState('');
  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);
  const [mapKey, setMapKey] = useState(0);
  const [address, setAddress] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('');
  const [postcode, setPostcode] = useState('');
  const [placeId, setPlaceId] = useState(props.placeId);
  const [lat, setLat] = useState('');
  const [lng, setLng] = useState('');
  const [openingHours, setOpeningHours] = useState([
    { day: 0, operational: false, open: '', close: '' },
    { day: 1, operational: false, open: '', close: '' },
    { day: 2, operational: true, open: '', close: '' },
    { day: 3, operational: false, open: '', close: '' },
    { day: 4, operational: false, open: '', close: '' },
    { day: 5, operational: true, open: '', close: '' },
    { day: 6, operational: false, open: '', close: '' },
  ]);
  const [isModalOpen, setModalOpen] = useState(false);
  console.log('props', props);

  useEffect(() => {
    setCity(props.city);
    setAddress(props.address);
    setState(props.state);
    setCountry(props.country);
    setPostcode(props.postcode);
    setLat(props.lat);
    setLng(props.lng);
    setOpeningHours(props.openingHours);
    setPlaceId(props.placeId);
  }, [props.city]);

  useEffect(() => {
    const tempCountries = [];
    getNames().map((name) => {
      tempCountries.push({
        value: name,
        label: name,
      });
    });
    setCountries(tempCountries);
  }, []);

  useEffect(() => {
    props.handleChange('address', address || props.address);
  }, [address]);
  useEffect(() => {
    props.handleChange('city', city || props.city);
  }, [city]);
  useEffect(() => {
    props.handleChange('country', country || props.country);
  }, [country]);
  useEffect(() => {
    props.handleChange('state', state || props.state);
  }, [state]);
  useEffect(() => {
    props.handleChange('postcode', postcode || props.postcode);
  }, [postcode]);
  useEffect(() => {
    props.handleChange('lat', lat || props.lat);
  }, [lat]);
  useEffect(() => {
    props.handleChange('lng', lng || props.lng);
  }, [lng]);
  useEffect(() => {
    props.handleChange('placeId', placeId || props.placeId);
  }, [placeId]);

  const changeAddress = (address) => {
    setLat(address.lat);
    setLng(address.lng);
    setAddress(address.address);
    setPostcode(address.postalCode);
    setCountry(getName(address.country));
    setState(address.state);
    setCity(address.city);
    setPlaceId(address.placeId);
    onOpeningHoursChange(address.openingHours);
  };

  const onCountryChage = (item) => {
    setCountry(item.value);
  };
  const onOpeningHoursChange = (hours) => {
    props.handleChange('openingHours', hours);
  };

  return (
    <ColumnOffsetBottom>
      <Column>
        <FieldLabel>SEARCH ADDRESS</FieldLabel>
        <PlaceAutoCompleteComponent
          apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
          placeholder="Search address"
          onChange={changeAddress}
        />
      </Column>
      <Column>
        <FieldLabel>ADDRESS LINE 1</FieldLabel>
        <InputMain
          value={address}
          name="address"
          onChange={(event) => setAddress(event.target.value)}
          maxLength={50}
          error={'address' in props.errors}
          placeholder="Business Address"
          onFocus={props.toggleFocused}
          onBlur={props.toggleFocused}
        />
      </Column>
      <Column>
        <HalfContentWrapper>
          <HalfContent>
            <FieldLabel>COUNTRY</FieldLabel>
            <DropDownComponent
              value={{
                value: country,
                label: country,
              }}
              name="country"
              error={'country' in props.errors}
              placeholder="Select your Country"
              onSelect={onCountryChage}
              options={countries}
              onFocus={props.toggleFocused}
              onBlur={props.toggleFocused}
            />
          </HalfContent>
          <HalfContent>
            <FieldLabel>STATE</FieldLabel>
            <InputMain
              value={state}
              name="state"
              onChange={(event) => setState(event.target.value)}
              maxLength={50}
              error={'state' in props.errors}
              placeholder="state"
              onFocus={props.toggleFocused}
              onBlur={props.toggleFocused}
            />
          </HalfContent>
        </HalfContentWrapper>
      </Column>
      <Column>
        <HalfContentWrapper>
          <HalfContent>
            <FieldLabel>TOWN OR CITY</FieldLabel>
            <InputMain
              name="city"
              maxLength={50}
              error={'city' in props.errors}
              value={city}
              placeholder="City"
              onChange={(event) => setCity(event.target.value)}
              onFocus={props.toggleFocused}
              onBlur={props.toggleFocused}
            />
          </HalfContent>
          <HalfContent>
            <FieldLabel>POSTCODE</FieldLabel>
            <InputMain
              name="postcode"
              maxLength={8}
              error={'postcode' in props.errors}
              value={postcode}
              placeholder="Postcode"
              onChange={(event) => setPostcode(event.target.value)}
              onFocus={props.toggleFocused}
              onBlur={props.toggleFocused}
            />
          </HalfContent>
        </HalfContentWrapper>
        <RowOffsetBottom>
          <Column>
            <SectionLabel>OPENING TIMES</SectionLabel>
            <OpeningTimesComponent handleChange={onOpeningHoursChange} />
          </Column>
        </RowOffsetBottom>
        <MapContainer
          key={mapKey}
          city={{
            label: city,
            lat,
            lng,
          }}
          apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
        />
      </Column>
      <StyledSection>
        <TooltipWrapper>
          <TooltipContainer>
            <StyledInputLabel>Google Place ID</StyledInputLabel>
            <TooltipText>
              The unique identifier for this location provided by Google Maps.
            </TooltipText>
          </TooltipContainer>{' '}
          <StyledInput
            value={placeId}
            name="placeId"
            error={'placeId' in props.errors}
            onChange={(event) => setPlaceId(event.target.value)}
            maxLength={50}
            placeholder={placeId || 'Enter Your Google Place ID'}
            onFocus={props.toggleFocused}
            onBlur={props.toggleFocused}
          />
        </TooltipWrapper>
        {/* {!!props.errors && (
          <StyledErrorMessage>{props.errors}</StyledErrorMessage>
        )} */}
        <PlaceIDFieldContainer onClick={() => setModalOpen(true)}>
          Find Google Place ID
        </PlaceIDFieldContainer>
        <PlaceIdModal
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
        />
      </StyledSection>
    </ColumnOffsetBottom>
  );
};
const ProfileMapAddressSelector = createSelector(
  selectProfileObject,
  selectProfileErrors,
  (profile, errors) => ({
    errors,
    city: profile.city.label,
    country: profile.country,
    state: profile.state,
    postcode: profile.postcode,
    address: profile.address,
    lat: profile.lat,
    lng: profile.lng,
    openingHours: profile.openingHours,
    placeId: profile.placeId,
  })
);
const mapStateToProps = ProfileMapAddressSelector;
const mapDispatchToProps = {};

export const StyledSection = styled.div`
  margin-bottom: 16px;

  ${({ flex }) => flex && `display: flex; justify-content: space-between;`};
`;
export const StyledInputLabel = styled.div`
  font-family: ${newTheme.fontFamily.primary.light};
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  padding-top: 32px;
`;

export const StyledInput = styled.input`
  font-family: ${newTheme.fontFamily.primary.light};
  width: 100%;
  padding: 17px 24px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  border-radius: 4px;
  color: ${({ value }) => (value ? '#131312' : '#959492')};
  /* border: 1px solid #959492; */
  ${({ borderColor }) => borderColor && 'border: #DFDFDE;'}

  border: 1px solid ${({ errors }) => (errors ? `red;` : '#DFDFDE;')};
  border: 1px solid
    ${({ borderColor, errors }) =>
      borderColor && errors ? `red;` : '#DFDFDE;'};
  border: 1px solid
    ${({ errorMessage }) => (errorMessage ? `red;` : '#DFDFDE;')};

  ${({ icon }) =>
    icon && `background: url(${icon}) no-repeat scroll 23px 15px;`}
  ${({ icon, vertical }) =>
    icon &&
    vertical &&
    `background: url(${icon}) no-repeat scroll 23px ${vertical}px;`}

	&:hover {
    border: 1px solid black;
  }
  &:focus {
    border: 1px solid black;
    outline: none;
  }
  &:active {
    border: 1px solid black;
  }
  &:focus-visible {
    border: 1px solid black;
    color: black;
  }

  @media (max-width: 450px) {
    ${({ icon }) =>
      icon && `background: url(${icon}) no-repeat scroll 8px 15px;`}
    padding-left: 34px;
  }
`;

export const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
`;

export const TooltipText = styled.div`
  visibility: hidden;
  width: 200px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  padding: 8px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Adjust to position tooltip */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;

  /* Arrow */
  &:after {
    content: '';
    position: absolute;
    top: 100%; /* Position below tooltip */
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: #333 transparent transparent transparent;
  }
`;

export const TooltipWrapper = styled.div`
  &:hover ${TooltipText} {
    visibility: visible;
    opacity: 1;
  }
`;

export const PlaceIDFieldContainer = styled.div`
  margin-top: 8px;
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
`;

export default connect(mapStateToProps, mapDispatchToProps)(MapAddress);
