import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router';

import { serializeForm, clearControllerFrom } from '../../utils/selectors';
import {
  Column,
  RowOffsetBottom,
  RightBlock,
  BoardComponent,
  Button,
  Row,
  HalfContentWrapper,
} from '../../components/MainComponents/MainComponents';
import ProfileViewContainer from '../../containers/ProfileViewContainer/ProfileViewContainer';
import ProfileEditingContainer from '../../containers/ProfileEditingContainer/ProfileEditingContainer';
import {
  ProfilePicker,
  ProfileScreenContentWrapper,
  ContentWrapper,
} from './styles';
import BottomControlPanel from '../../components/BottomControlPanel/BottomControlPanel';
import { MainContentOffset } from '../MainScreen/styles';
import profileActions from '../../redux/profile/profile.actions';
import postsActions from '../../redux/posts/posts.actions';
import { selectTemp } from '../../redux/profile/profile.selectors';
import { selectUserObject } from '../../redux/user/user.selectors';

class ProfileScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedProfile: null,
      profileOptions: [{ value: null, label: 'Loading...' }],
    };
  }

  componentDidMount() {
    this.setState({
      selectedProfile: this.props.user.profile[0],
      profileOptions: this.props.user.profile.map((p) => {
        return {
          value: p._id,
          label: p.name,
        };
      }),
    });
    this.fetchProfile();
    this.fetchPosts();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedProfile !== this.state.selectedProfile) {
      this.props.clearProfileAddress();
      this.fetchProfile();
    }
  }

  fetchProfile = () => {
    if (!this.state.selectedProfile) {
      this.props.fetchProfile(this.props.user.profile[0]);
    } else {
      this.props.fetchProfile(this.state.selectedProfile._id);
    }
  };

  fetchPosts = () => {
    this.props.fetchPosts();
  };

  formSubmit = (event) => {
    event.preventDefault();
    const data = serializeForm(event.target);
    const updatedProfile = {
      name: data.name,
      logo: data.logo,
      backgroundImage: data.backgroundImage,
      description: data.description,
      address: data.address,
      postcode: data.postcode,
      coffeeBeans: data.coffeeBeans,
      coffeeMachine: data.coffeeMachine,
      coffeeGrinder: data.coffeeGrinder,
    };

    const {
      temp: {
        lat,
        lng,
        city,
        state,
        country,
        coffee_options,
        coffeeMachine,
        coffeeGrinder,
        coffeeBeans,
        openingHours,
        top_features,
        highlights,
        logo,
        placeId,
      },
    } = this.props;
    if (lat && lng) {
      updatedProfile.lat = lat;
      updatedProfile.lng = lng;
    }
    updatedProfile.city = city;
    updatedProfile.state = state;
    updatedProfile.country = country;
    updatedProfile.coffee_options = coffee_options;
    updatedProfile.coffee_machine = coffeeMachine;
    updatedProfile.coffee_grinder = coffeeGrinder;
    updatedProfile.coffee_bean = coffeeBeans;
    updatedProfile.openingHours = openingHours;
    updatedProfile.top_features = top_features;
    updatedProfile.highlights = highlights;
    updatedProfile.placeId = placeId;

    updatedProfile.logo = logo;

    this.props.updateProfile(this.state.selectedProfile._id, updatedProfile);

    this.setState((state) => ({
      profileOptions: state.profileOptions.map((i) => {
        if (i.value === state.selectedProfile._id) {
          return {
            value: i.value,
            label: updatedProfile.name,
          };
        }
        return i;
      }),
    }));
  };

  resetCurrentForm = (event) => {
    clearControllerFrom(event.target);
  };

  onSelectedProfileChange = (item) => {
    this.setState({
      selectedProfile: this.props.user.profile.find(
        (p) => p._id === item.value
      ),
    });
  };

  render() {
    return (
      <ContentWrapper>
        <Column wrap>
          <MainContentOffset>
            <ProfilePicker
              value={
                this.state.selectedProfile
                  ? {
                      value: this.state.selectedProfile._id,
                      label: this.state.selectedProfile.name,
                    }
                  : this.state.profileOptions[0]
              }
              name="selectedProfile"
              placeholder="Select profile"
              onSelect={(item) => this.onSelectedProfileChange(item)}
              options={this.state.profileOptions}
            />
            <Button
              onClick={() => {
                this.props.clearProfile();
                this.props.history.push('/auth/profile');
              }}
            >
              ADD PROFILE
            </Button>
          </MainContentOffset>
        </Column>
        <Column
          as="form"
          onSubmit={this.formSubmit}
          onReset={this.resetCurrentForm}
        >
          <MainContentOffset>
            <ProfileScreenContentWrapper>
              <RowOffsetBottom wrap>
                <ProfileViewContainer />
                <RightBlock
                  style={{ boxShadow: '0 20px 50px rgba(0, 0, 0, 0.05)' }}
                  as={BoardComponent}
                  direction="row"
                >
                  <ProfileEditingContainer />
                </RightBlock>
              </RowOffsetBottom>
            </ProfileScreenContentWrapper>
          </MainContentOffset>

          <BottomControlPanel />
        </Column>
      </ContentWrapper>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  user: selectUserObject,
  temp: selectTemp,
});

const mapDispatchToProps = {
  fetchProfile: profileActions.fetchPending,
  fetchPosts: postsActions.fetchPending,
  updateProfile: profileActions.fetchUpdatePending,
  clearProfile: profileActions.clearProfile,
  clearProfileAddress: profileActions.clearProfileAddress,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProfileScreen));
